import React, { useState } from 'react'
import Configue from './pages/Configue'
import Header from './component/Header'
import Sidebar from './component/Sidebar'
import Listing from './pages/Listing';
import Aryabots from './pages/Aryabots';
import Edit from './pages/Edit';
import { Route, Routes } from 'react-router-dom';
import Preview from './pages/Preview';
import { ToastContainer } from 'react-toastify';
import './fonts.css';
import Table from './pages/Leads-Gen/Table';
import Temp from './pages/Leads-Gen/Temp';
import FileUpload from './pages/Leads-Gen/FileUpload';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    console.log("hey")
  };

  return (
    <>
    <Routes>

      <Route path='/' element = { <Aryabots/>}/>
      <Route path='/listing' element = { <Listing/> } />
      <Route path='/edit/:gpt_name' element = { <Edit/> }/>
      <Route path='/configue' element = { <Configue/> }/>
      <Route path='/preview/:gpt_name' element = { <Preview/> }/>
      <Route path='/fileupload' element = { <FileUpload/> }/>
      <Route path='/table' element = { <Table/>}/>
    </Routes>
    <ToastContainer/>
    {/* <Temp /> */}
    {/* <Table/>  */}
     {/* <Test/>/ */}
    {/* <FileUpload/> */}
 

    </>
  )
}

export default App;