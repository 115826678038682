import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { X } from 'react-feather';
import { HOST2 } from '../../Api/api';
import { toast } from 'react-toastify';


function Temp({setShowparameter, Product_Name, Sub_Category}) {
    const[attribute, setAttribute] = useState({});//to show attribute parameter
    const [formdata,setFormdata] = useState({
    Product_Title: "",
    Parent_SKU_Id: "",
    SKU_Id: "",
    HSN_Code: "",
    Short_Description: "",
    Long_Description: "",
    MRP: "",
    Sales_Price: "",
    Payment_Method: "",
    UOM_Type: "",
    UOM_Value: "",
    Package_Length: "",
    Package_Width: "",
    Package_Height: "",
    Package_Weight: "",
    Volumetric_Weight: "",
    Refund_Available: "",
    Cancel_Available: "",
    Return_Time: "",
    Seller_Return_Pickup_Availabe: "",
    Time_to_Ship: "",
    Expected_Delivery_Time: "",
    Expected_Delivery_Charge: "",
    Name: "",
    Consumer_Care_Email: "",
    Mobile_Number: "",
    Manufacturer_Name: "",
    Manufacturer_Address: "",
    Generic_Name: "",
    Net_Quantity_or_Measure_of_Commodity_in_pkg: "",
    Launched_Year: "",
    Country_of_Origin: ""
    });//to show the value of the perimeter

    useEffect(()=>{
        getData()
    },[]);

    const handleChange = (e)=>{
        const {name, value} = e.target;
        setFormdata((prevData)=>({
            ...prevData,
            [name]:value
        }))
    }

  //function to save data
  const handleSubmit = async()=>{
    const parameter_data = {...formdata,Product_Name:Product_Name};
    try{
      const response = await axios.post(HOST2 + "/edit_and_update_data",parameter_data,{
        headers:{
          'Content-Type': 'application/json',
          Accept: 'application/json',
      }
      })
      if(response.status === 200){
        toast.success("Data updated sucessfully" || response?.data?.message)
      }
    }
    catch(error){
        toast.error("Failed to update data" || error.response?.data?.message)
    }
    finally{
        setShowparameter(false);
    }
  }

  //function to get data
  const getData = async()=>{
    try{
    const response = await axios.post(HOST2 + "/fetch_product_data",
        {"Product_Name":Product_Name,"Sub_Category":Sub_Category},{
            headers:{
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        });
    if(response.status === 200){
        setAttribute(response.data.Attribute_Parameter);
        setFormdata((prevData)=>({
            ...prevData,
            ...response.data.data
        }));
    }
    }
    catch(error){
    console.log(error.response?.data);
    }
  }
  //drop down
  const uomType = ["UNIT","DOZEN","GRAM","KILOGRAM","TONNE","LITRE","MILLILITRE"]
  const time = ["Within 45 Minutes","Within 5 Working Hours","Within 24 Working Hours","Within 2 Working Days","Within 3 Working Days","Within 4 Working Days","Within 5 Working Days"]
  const special_feature = ["Laser","GPS","Night Vision","Frequency","AMOLED Screen","Dolby Audio"]
  const storage_unit = ["MB","GB","TB"]
  const compatible_devices = ["Laptop","Desktop","Tablet","Mobile Phone"]
  const connectivity = ["Bluetooth","LTE","Wi-Fi","Wireless","USB","HDMI"]
  const printer_output = ["Colour","Monochrome"]
  const storage_type = ["SSD","HDD"]
  const dropdownOptions = ["Special_Feature", "Connectivity", "Storage_Type","Storage_Unit","ROM_Unit","RAM_Unit","Compactible","Printer_Output"]; // Array specifying which options render a dropdown

  const getDropdownOptions = (attributeKey) => {
    switch (attributeKey) {
      case "Special_Feature": return special_feature;
      case "Connectivity": return connectivity;
      case "Storage_Type": return storage_type;
      case "Storage_Unit": return storage_unit;
      case "ROM_Unit": return storage_unit;
      case "RAM_Unit": return storage_unit;
      case "Compactible": return compatible_devices;
      case "Printer_Output": return printer_output;  
      default: return [];
    }
  };

    return (
      <div className='modal-overlay'>
        <div className="modal show" style={{ display: "block", height: "90vh" }} role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header bg-leads-blue d-flex justify-content-between">
              <h4 className="modal-title fs-5 text-white fw-bold">{Product_Name}</h4>
              <X size={24} color='#fff' className='cursor-pointer' onClick={()=>setShowparameter(false)}/>
            </div>
            <div className="modal-body">
            <Form>
                <h6 className='fw-bold'>Product Identifiers</h6>
                <p className='mb-1 mt-4 font-14 text-color-7'>Product Title</p>
                <Form.Control type="text" name='Product_Title' value={formdata.Product_Title} size="sm" onChange={handleChange} />
                <p className='mb-1 mt-4 font-14 text-color-7'>Parent SKU Id</p>
                <Form.Control type="text" name='Parent_SKU_Id' value={formdata.Parent_SKU_Id} onChange={handleChange} />
                <p className='mb-1 mt-4 font-14 text-color-7'>SKU Id</p>
                <Form.Control type="text" name='SKU_Id' value={formdata.SKU_Id} onChange={handleChange}/>
                <p className='mb-1 mt-4 font-14 text-color-7'>HSN Reference Code</p>
                <Form.Control type="text" name='HSN_Code' value={formdata.HSN_Code} onChange={handleChange} />
                <h6 className='mt-5 fw-bold'>Product Common Details</h6>
                <p className='mb-1 mt-4 font-14 text-color-7'>Short Description</p>
                <Form.Control as="textarea" rows={5} name='Short_Description' value={formdata.Short_Description} onChange={handleChange}/>
                <p className='mb-1 mt-4 font-14 text-color-7'>Long Description</p>
                <Form.Control as="textarea" rows={5}  name='Long_Description' value={formdata.Long_Description} onChange={handleChange}/>
                <h6 className='mt-5 fw-bold'>Product Pricing Details</h6>
                <p className='mb-1 mt-4 font-14 text-color-7'>MRP</p>
                <Form.Control type="text" name='MRP' size="sm" value={formdata.MRP} onChange={handleChange}/>
                <p className='mb-1 mt-4 font-14 text-color-7'>Sales Price</p>
                <Form.Control type="text" name='Sales_Price' value={formdata.Sales_Price} onChange={handleChange}/>
                <p className='mb-1 mt-4 font-14 text-color-7'>Payment Method</p>
                <Form.Select name='Payment_Method' value={formdata.Payment_Method} onChange={handleChange}>
                    <option disabled selected value="" className='d-none'>Payment Method</option>
                    <option value="PRE-PAID">PRE-PAID</option>
                    <option value="">No Select</option>
                </Form.Select>
                <h6 className='mt-5 fw-bold'>UOM Details</h6>
                <p className='mb-1 mt-4 font-14 text-color-7'>UOM Type</p>
                <Form.Select name='UOM_Type' value={formdata.UOM_Type} onChange={handleChange}>
                    <option disabled selected value="" className='d-none'>UOM Type</option>
                    {
                      uomType.map((option,index)=>(
                        <option key={index} value={option}>{option}</option>
                      ))
                    }
                    <option value="">No Select</option>
                </Form.Select>
                <p className='mb-1 mt-4 font-14 text-color-7'>UOM Value</p>
                <Form.Control type="text" name='UOM_Value' value={formdata.UOM_Value} onChange={handleChange} />
               {attribute &&(<h6 className='mt-5 fw-bold'>Attributes</h6>)}
                {
                    attribute && Object.keys(attribute).map((key,index)=>(
                    <div key={index}>
                        <p  className='mb-1 mt-4 font-14 text-color-7'>{key}</p>
                        {
                          dropdownOptions.includes(key) ? (
                            <Form.Select name={key} value={formdata[key] || ""} onChange={handleChange}>
                              <option disabled selected  value="" className='d-none'>Select {key}</option>
                              {getDropdownOptions(key).map((option, idx) => (
                              <option key={idx} value={option}>{option}</option>
                              ))}
                            </Form.Select>

                          ):
                          (
                            <Form.Control type="text" name={key} value={formdata[key] || ""} onChange={handleChange} />
                          )
                        }
                    </div>
                    ))
                }
                <h6 className='mt-5 fw-bold'>Package Dimensions</h6>
                <p className='mb-1 mt-4 font-14 text-color-7'>Package Length(cm)</p>
                <Form.Control type="text" size="sm" name='Package_Length' value={formdata.Package_Length} onChange={handleChange} />
                <p className='mb-1 mt-4 font-14 text-color-7'>Package Width(cm)</p>
                <Form.Control type="text" size="sm" name='Package_Width' value={formdata.Package_Width} onChange={handleChange} />
                <p className='mb-1 mt-4 font-14 text-color-7'>Package Height(cm)</p>
                <Form.Control type="text" name='Package_Height' value={formdata.Package_Height} onChange={handleChange} />
                <p className='mb-1 mt-4 font-14 text-color-7'>Package Weight(gm)</p>
                <Form.Control type="text" name='Package_Weight' value={formdata.Package_Weight} onChange={handleChange} />
                <p className='mb-1 mt-4 font-14 text-color-7'>Volumetric Weight(gm)</p>
                <Form.Control type="text" name='Volumetric_Weight' value={formdata.Volumetric_Weight} onChange={handleChange}/>
                <h6 className='mt-5 fw-bold'>Product Cancellation Terms</h6>
                <p className='mb-1 mt-4 font-14 text-color-7'>Refund Available</p>
                <Form.Select name='Refund_Available' value={formdata.Refund_Available} onChange={handleChange}>
                    <option disabled selected value="" className='d-none'>Refund Available</option>
                    <option value="True">True</option>
                    <option value="False">False</option>
                    <option value="">No Select</option>
                </Form.Select>
                <p className='mb-1 mt-4 font-14 text-color-7'>Cancel Available</p>
                <Form.Select name='Cancel_Available' value={formdata.Cancel_Available} onChange={handleChange}>
                    <option disabled selected value="" className='d-none'>Cancel Available</option>
                    <option value="True">True</option>
                    <option value="False">False</option>
                    <option value="">No Select</option>
                </Form.Select>
                {/* <Form.Control type="text" name='Cancel_Available' value={formdata.Cancel_Available} onChange={handleChange}/> */}
                <p className='mb-1 mt-4 font-14 text-color-7'>Return Time</p>
                <Form.Select name='Return_Time' value={formdata.Return_Time} onChange={handleChange}>
                    <option disabled selected value="" className='d-none'>Return Time</option>
                    {
                      time.map((option,index)=>(
                        <option key={index} value={option}>{option}</option>
                      ))
                    }
                    <option value="">No Select</option>
                </Form.Select>
                <p className='mb-1 mt-4 font-14 text-color-7'>Seller Return Pickup Availabe</p>
                <Form.Select name='Seller_Return_Pickup_Availabe' value={formdata.Seller_Return_Pickup_Availabe} onChange={handleChange}>
                    <option disabled selected value="" className='d-none'>Seller Return Pickup Availabe</option>
                    <option value="True">True</option>
                    <option value="False">False</option>
                    <option value="">No Select</option>
                </Form.Select>
                <h6 className='mt-5 fw-bold'>Delivery Sla Details</h6>
                <p className='mb-1 mt-4 font-14 text-color-7'>Time to Ship*</p>
                <Form.Select name='Time_to_Ship' value={formdata.Time_to_Ship} onChange={handleChange}>
                    <option disabled selected value="" className='d-none'>Time to Ship</option>
                    {
                      time.map((option,index)=>(
                        <option key={index} value={option}>{option}</option>
                      ))
                    }
                    <option value="">No Select</option>
                </Form.Select>
                <p className='mb-1 mt-4 font-14 text-color-7'>Expected Delivery Time</p>
                <Form.Select name='Expected_Delivery_Time' value={formdata.Expected_Delivery_Time} onChange={handleChange}>
                    <option disabled selected value="" className='d-none'>Expected Delivery Time</option>
                    {
                      time.map((option,index)=>(
                        <option key={index} value={option}>{option}</option>
                      ))
                    }
                    <option value="">No Select</option>
                </Form.Select>
                <p className='mb-1 mt-4 font-14 text-color-7'>Expected Delivery Charge</p>
                <Form.Control type="text"name='Expected_Delivery_Charge' onChange={handleChange}/>
                <h6 className='mt-5 fw-bold'>Consumer Care Details</h6>
                <p className='mb-1 mt-4 font-14 text-color-7'>Name*</p>
                <Form.Control type="text" size="sm" name='Name' onChange={handleChange}/>
                <p className='mb-1 mt-4 font-14 text-color-7'>Consumer Care Email*</p>
                <Form.Control type="text" name='Consumer_Care_Email' onChange={handleChange} />
                <p className='mb-1 mt-4 font-14 text-color-7'>Mobile Number</p>
                <Form.Control type="text" name='Mobile_Number' onChange={handleChange}/>
                <h6 className='mt-5 fw-bold'>Packaged Commodities</h6>
                <p className='mb-1 mt-4 font-14 text-color-7'>Manufacturer Name</p>
                <Form.Control type="text" size="sm" name='Manufacturer_Name' value={formdata.Manufacturer_Name} onChange={handleChange} />
                <p className='mb-1 mt-4 font-14 text-color-7'>Address</p>
                <Form.Control type="text" name='Manufacturer_Address' value={formdata.Manufacturer_Address} onChange={handleChange} />
                <p className='mb-1 mt-4 font-14 text-color-7'>Generic Name</p>
                <Form.Control type="text" name='Generic_Name' value={formdata.Generic_Name} onChange={handleChange} />
                <p className='mb-1 mt-4 font-14 text-color-7'>Net Quantity or Measure of Commodity in pkg</p>
                <Form.Control type="text" name='Net_Quantity_or_Measure_of_Commodity_in_pkg' value={formdata.Net_Quantity_or_Measure_of_Commodity_in_pkg} onChange={handleChange}/>
                <p className='mb-1 mt-4 font-14 text-color-7'>Year of manufacture/launched</p>
                <Form.Control type="text" name='Launched_Year' value={formdata.Launched_Year} onChange={handleChange}/>
                <p className='mb-1 mt-4 font-14 text-color-7'>Country of Origin</p>
                <Form.Control type="text" name='Country_of_Origin' value={formdata.Country_of_Origin} onChange={handleChange} />
            </Form>
            </div>
            <div className="modal-footer">
            <Button variant="" onClick={()=>setShowparameter(false)}>Cancel</Button>
            <Button className='px-5 bg-leads-blue'  onClick={handleSubmit}>Save</Button>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
}

export default Temp;