import React, { useEffect, useState } from 'react';
import Header from '../component/Header';
import createnew from '../images/createnew.png';
import LOGO from '../images/logo.svg';
import { Edit2, Trash2, Minus } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { HOST } from '../Api/api';

function Listing() {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteGptName, setDeleteGptName] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { role } = location.state || {}
    const { username } = location.state || {};

    useEffect(() => {
        fetch_Data();
    }, []);

    const fetch_Data = async() => {
        setLoading(true);
        try{
            const response = await fetch(HOST + "/accounts_list");
            const result = await response.json();
            if(response.ok){
                setData(result);
            }
            else{
                toast.error(response.message || "Failed to fetch data");
            }
        }
        catch (error) {
            toast.error("Something went wrong");
        } finally {
            setLoading(false);  // Stop loader
        }
    };

    const handle_Delete = () => {
        if (deleteGptName) {
            fetch(HOST + `/delete_account/${deleteGptName}`, {
                method: "PUT",
                headers: {
                    "Accept": "application/json",
                }
            })
                .then((response) => {
                    if (response.ok) {
                        fetch_Data();
                    } else {
                        toast.error("failed to delete data");
                    }
                })
                .catch(() => {
                    toast.error("something went wrong while deleting data");
                })
                .finally(() => {
                    setShowModal(false);
                });
        }
    };

    //updating status
    const handleStatusUpdate = async(gpt_name, status)=>{
        try{
            const response = await axios.post(HOST + "/status",{gpt_name:gpt_name,status:status},{
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            })
            if(response.status === 200){
                setData(prevData =>
                    prevData.map(item =>
                        item.gpt_name === gpt_name ? { ...item, status } : item
                    )
                );
                console.log(response.data.message);
            }            
        }
        catch(error){
            console.log(error);
            toast.error("Something went wrong");
        }
    }

    // Handle navigation to chat based on role and status

    const handlePreview = (gpt_name, logoPath, user_type, status) => {
        if (role === 'Admin') {
            // Admins can always navigate to the preview page
            navigate(`/preview/${gpt_name}`, { state: { logoPath, user_type } });
        } else if (role === 'Finance Manager' || role === 'Operations Manager' || role === 'Sales Manager') {
            // Managers can navigate only if the status is 'Approved'
            if (status === 'Approved') {
                navigate(`/preview/${gpt_name}`, { state: { logoPath, user_type } });
            } else {
                toast.error("You can only chat with approved GPTs.");
            }
        } else if (role === 'Finance Executive' || role === 'Operations Executive' || role === 'Sales Executive') {
            // Executives can navigate to chat only if the status is 'Approved'
            if (status === 'Approved') {
                navigate(`/preview/${gpt_name}`, { state: { logoPath, user_type } });
            } else {
                toast.error("You can only chat with approved GPTs.");
            }
        } else {
            toast.error("You don't have permission to access this page.");
        }
    };


    const filteredData = data.filter(item => {
        const matchSearch = item.gpt_name.toLowerCase().startsWith(searchQuery.toLowerCase());
        
        if (role === 'Finance Executive' || role === 'Operations Executive' || role === 'Sales Executive') {
            return matchSearch && item.status === 'Approved';
        } else if (role === "Admin" || role === 'Finance Manager' || role === 'Operations Manager' || role === 'Sales Manager') {
            return matchSearch;
        } else {
            return false;  // Return false if no match
        }
    });
    

    return (
        <div>
            <Header header_name="My GPTs" />
            <div className='p-3 p-lg-5'>
                <div className='d-flex flex-column align-items-center'>
                    <div className='d-flex flex-row create-gpt'>
                        {(role === 'Finance Manager' || role === 'Operations Manager' || role === 'Sales Manager' || role === 'Admin') &&
                            <Link to={'/configue'} state={{ username,role }}>
                            <img className='create-new-icon' src={createnew} alt="" />
                            </Link> 
                        }
                        <div className='d-flex flex-column ms-2'>
                            {(role === 'Finance Manager' || role === 'Operations Manager' || role === 'Sales Manager' || role === 'Admin') &&
                            <h5>Create a GPT</h5>
                            }
                            <p className='text-color-a font-14 mb-2'>Customize a version of ChatGPT for a specific purpose.</p>
                        </div>
                    </div>
                    <hr className='m-0 w-75' />
                </div>
                <div className='d-flex justify-content-center'>
                    <input
                        type="text"
                        className="form-control w-50 mt-3 mb-3"
                        placeholder="Search GPTs..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className='d-flex justify-content-center'>

                    {loading ? (
                        <div>Loading...</div> // Replace this with your loader component if you have one
                    ) : (
                        <table className=''>
                            <thead className='bg-table-head'>
                                <tr>
                                    <th className='px-2 py-4 px-md-2 px-lg-5'>GPT Name</th>
                                    <th className='px-2 py-4 px-md-4 px-lg-5'>Description</th>
                                    {(role === 'Finance Manager' || role === 'Operations Manager' || role === 'Sales Manager' || role === 'Admin') && (
                                        <th className='px-2 py-4 px-md-2 px-lg-5'>Status</th>
                                    )}
                                    {(role === 'Finance Manager' || role === 'Operations Manager' || role === 'Sales Manager' || role === 'Admin') && (
                                        <th className='px-2 py-4 px-md-2 px-lg-5 '>Actions</th>
                                    )}
                                    {/* <th className='px-2 py-4 px-md-2 px-lg-5'>Action</th> */}
                                </tr>
                            </thead>
                            {filteredData.length > 0 ? (
                                filteredData.map(item => (
                                    <tr className='row-style' key={item.gpt_name}>
                                        <td
                                            className='px-2 py-4 px-md-2 px-lg-5 font-500'
                                            onClick={() => handlePreview(item.gpt_name, item.logo_path, item.user_type, item.status)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <img src={item.logo_path || LOGO} className="listing-logo me-2 rounded-circle" alt="logo" />
                                            {item.gpt_name}
                                        </td>
                                        <td className='px-2 py-4 px-md-4 px-lg-5 text-color-a font-14 font-500 desc-width'>{item.description}</td>
                                        {(role === 'Finance Manager' || role === 'Operations Manager' || role === 'Sales Manager') && (
                                            <td className='px-2 py-4 px-md-2 px-lg-5'>
                                                <span className={`badge ${item.status === 'Approved' ? 'bg-green text-success p-2' : item.status === 'Disapproved' ? 'bg-red text-danger p-2' : 'bg-yellow text-warning p-2'}`}>
                                                    {/* {item.status.charAt(0).toUpperCase() + item.status.slice(1)} */}
                                                    {item.status}
                                                </span>
                                            </td>
                                        )}
                                        {role === 'Admin' && (
                                            <td className='px-2 py-4 px-md-2 px-lg-5'>
                                                {
                                                    item.status !== "Disapproved" && (
                                                        <button
                                                            className='btn btn-sm bg-green text-success fw-bold me-2 zoom-button'
                                                            onClick={() => handleStatusUpdate(item.gpt_name, 'Approved')}
                                                            disabled={item.status === 'Approved'}
                                                        >
                                                            {item.status === 'Approved' ? 'Approved' : 'Approve'}
                                                        </button>
                                                    )
                                                }

                                                {
                                                    item.status !== "Approved" && (
                                                        <button
                                                            className='btn btn-sm bg-red text-danger fw-bold zoom-button'
                                                            onClick={() => handleStatusUpdate(item.gpt_name, 'Disapproved')}
                                                            disabled={item.status === 'Disapproved'}
                                                        >
                                                            {item.status === 'Disapproved' ? 'Disapproved' : 'Disapprove'}
                                                        </button>
                                                    )
                                                }

                                            </td>
                                        )}
                                        {(role === 'Admin' || ((role === 'Finance Manager' || role === 'Operations Manager' || role === 'Sales Manager') && item.username === username)) ? (
                                            <td className='px-2 py-4 px-md-2 px-lg-5'>
                                                <Link to={`/edit/${item.gpt_name}`} state={{username, role}}>
                                                    <button className='border-0 bg-transparent'>
                                                        <Edit2 className='' size={18} color='#777' />
                                                    </button>
                                                </Link>
                                                <button
                                                    className='border-0 bg-transparent'
                                                    onClick={() => {
                                                        setShowModal(true);
                                                        setDeleteGptName(item.gpt_name);
                                                    }}
                                                >
                                                    <Trash2 className='ms-2' size={18} color='#777' />
                                                </button>
                                            </td>
                                        ):(
                                            (role === 'Finance Manager' || role === 'Operations Manager' || role === 'Sales Manager') && (
                                                <td className='px-2 py-4 px-md-2 px-lg-5 d-flex justify-content-center'>
                                                <button className='border-0 bg-transparent'>
                                                    <Minus size={18} className='ms-2' color='#777' />
                                                </button>
                                                </td>
                                            )
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className='text-center py-4'>
                                        No GPT found.
                                    </td>
                                </tr>
                            )}
                        </table>
                    )}
                    {showModal && (
                        <div className="modal show" style={{ display: "block" }} role="dialog">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title fs-5">Delete GPT?</h4>
                                        <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <p className='text-color-a font-14 mb-0'>Are you sure you want to delete this GPT?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                                        <button type="button" className="btn btn-danger" onClick={handle_Delete}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Listing;
