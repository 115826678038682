import React, { useState } from 'react'
import Maskgroup from "../images/Maskgroup.png";
import Company from "../images/Company.svg";
import Business from "../images/Business.svg";
import Functional from "../images/Functional.svg";
import { Search } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import LOGO from '../images/logo.svg';
import coming from '../images/coming_soon.png'
import aienabled from '../images/AI Enabled.png'
import notaienabled from '../images/Not-AI Enabled.png'


function Aryabots() {
    const [searchQuery, setSearchQuery] = useState('');
    const [tab, setTab] = useState('company');
    const navigate = useNavigate();

    // Acessing username and role from the url
    const queryParams = new URLSearchParams(window.location.search);
    const username = queryParams.get("email");
    const role = queryParams.get('role');

    // Single array containing all the data with a category field
    const data = [
        { img: LOGO, boat: "CM Chatbot", desc: "The Cyber Media Chatbox is an internal AI-powered tool designed to streamline communication and collaboration across teams. It offers real-time assistance, automates routine queries, and provides quick access to company resources, helping employees work more efficiently and stay informed.", category: "company", path: "listing" },
        { img: Maskgroup, boat: "CM AI SEO Content Generator", desc: "The CM AI SEO Content Generator is designed to streamline product listing creation for e-commerce websites. Utilizing advanced Gen AI algorithms, it crafts highly optimized, engaging, and structured content tailored for SEO. This tool helps businesses enhance their product visibility, ensuring better search rankings and increased sales potential.", category: "company",path:"fileupload"}
    ];
    // Filter data based on the selected tab (category)
    const filteredData = data
        .filter(item => item.category === tab);
    // .filter(item => item.boat.toLowerCase().startsWith(searchQuery.toLowerCase()));

    // If search query is active, show results across all categories
    const searchResults = searchQuery
        ? data.filter(item => item.boat.toLowerCase().startsWith(searchQuery.toLowerCase()))
        : filteredData;

    // to navigate
    const handlerun = (path) => {
        if (path) {
            navigate(`/${path}`, { state: { username, role } });
        }
    }

    return (
            <div className="arya-bg d-flex flex-column align-items-center height-fix">
                <div className="top-section mt-5">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <h2 className='fw-bold'><span className="text-primary">Arya AI Bots</span> Library</h2>
                        <p className="mt-3 w-75 text-center text-color-a">Browse, use, and enjoy the best ai bots curated just for you. To enhance your
                            productivity with intuitive features and streamlined workflows.</p>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <div className=" bg-white rounded-5 shadow-sm search-box d-flex align-items-center">
                            <Search className='ms-4' size={24} color='#777' />
                            <input
                                type="text"
                                className="form-control me-2 border-0"
                                placeholder="Search your apps..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='mt-5'>
                        <div class="mt-4">
                            <ul class="nav nav-tabs mx-3 mx-lg-0 mx-md-5 justify-content-center justify-content-lg-start justify-content-md-start">
                                <div className={tab === "company" ? "border-bottom border-primary border-3" : ""}>
                                    <li class="nav-item border-0 cursor-pointer mb-2" onClick={() => setTab("company")}>
                                        <img src={Company} alt="" /> <span className='ms-0 ms-lg-2 ms-md-2'> Company </span>
                                    </li>
                                </div>
                                <div className={tab === "business" ? "border-bottom border-primary border-3 ms-3 ms-md-5 ms-lg-5" : "ms-3 ms-md-5 ms-lg-5"}>
                                    <li class="nav-item border-0 cursor-pointer mb-2" onClick={() => setTab("business")}>
                                        <img src={Business} alt="" /> <span className='ms-0 ms-lg-2 ms-md-2'> Business </span>
                                    </li>
                                </div>
                                <div className={tab === "functional" ? "border-bottom border-primary border-3 ms-3 ms-md-5 ms-lg-5" : "ms-3 ms-md-5 ms-lg-5"}>
                                    <li class="nav-item border-0 cursor-pointer mb-2" onClick={() => setTab("functional")} >
                                        <img src={Functional} alt="" /> <span className='ms-0 ms-lg-2 ms-md-2'> Functional </span>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bottom-section d-flex flex-column justify-content-center my-5 rounded-4">
                    {
                        (searchResults.length > 0) &&(
                            <div className="mt-4">
                            <p className="text-center text-secondary">Most popular apps by our community</p>
                        </div>
                        )
                    }
                    <div className="d-flex w-100 p-3 flex-wrap my-4">
                        {filteredData.length === 0 ? (
                            // Case 1: No data for the selected tab, show "coming soon"
                            <div className="text-center w-100">
                                <img src={coming} alt="Coming Soon" className="img-fluid" />
                            </div>
                        ) : searchResults.length > 0 ? (
                            // Case 2: Show search results when available
                            searchResults.map((item, index) => (
                                <div className="col-12 col-md-6 col-lg-6" key={index}>
                                    <div className="bg-white rounded m-2 shadow-sm">
                                        <div className="arya-bg p-3 d-flex justify-content-center rounded-top">
                                            <div className='rounded-circle border border-2 border-white d-flex justify-content-center align-items-center logo-ring'>
                                                <div className='rounded-circle bg-white logo-ring-inner d-flex justify-content-center align-items-center'>
                                                    <img className="logo-bots cursor-pointer" onClick={() => handlerun(item?.path)} src={item.img} alt={item.boat} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-4">
                                            <div className='d-flex justify-content-between'>
                                                <h5 className='fw-bold'>{item.boat}</h5>
                                                {
                                                    item.path?(
                                                        <img className='ai-enabled' src={aienabled} alt="" />
                                                    ):
                                                    (
                                                        <img className='ai-enabled' src={notaienabled} alt="" />
                                                    )
                                                }
                                            </div>
                                            <p className='text-color-a mt-4'>{item.desc}</p>
                                        </div>
                                        <div className="d-flex justify-content-end p-4">
                                            <button className="btn btn-primary col-3" onClick={() => handlerun(item?.path)}>Run</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // Case 3: No search results for the current tab, show "No results found"
                            <div className='mx-auto text-center p-5'>
                            <h1>404!</h1>
                            <p className="text-center w-100">No results found</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
    )
}

export default Aryabots